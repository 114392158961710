import React from 'react'
// import { graphql } from 'gatsby'
// fix error: 'graphql' is defined but never used.

import Layout from '../components/layout'
import Seo from '../components/seo'
// Imported JSX component SEO must be in PascalCase

const NotFoundPage = ({ data, location }) => {
  return (
    <Layout>
      <Seo title='Not Found' />
      <article>
        <h1>Not Found</h1>
        <p>Oops. You just hit a page that doesn't exist :(</p>
      </article>
    </Layout>
  )
}

export default NotFoundPage
